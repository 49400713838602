import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CartContext } from '../contexts/CartContext';

const ProductDetail = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [addedToCart, setAddedToCart] = useState(false);
    const { addToCart } = useContext(CartContext);

    useEffect(() => {
        fetch(`/api/products/${id}/`)
            .then(response => response.json())
            .then(data => setProduct(data))
            .catch(error => console.error('Error fetching product:', error));
    }, [id]);

    const handleAddToCart = () => {
        addToCart(product);
        setAddedToCart(true);
        setTimeout(() => setAddedToCart(false), 2000);
    };

    if (!product) {
        return <div>Loading...</div>;
    }

    return (
        <div style={styles.container}>
            <div className="product-detail" style={styles.productDetail}>
                <div style={styles.imageContainer}>
                    <img src={product.images[0]?.image} alt={product.name} style={styles.image} />
                </div>
                <div style={styles.descriptionContainer}>
                    <p>Nombre: {product.name}</p>
                    <p>Descripcion: {product.description}</p>
                    <p>Precio: ${product.price}</p>
                    <button onClick={handleAddToCart} style={styles.addToCartButton}>Agreagar al Carrito</button>
                    {addedToCart && <p style={styles.addedToCartMessage}>Agreagado al Carrito!</p>}
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        width: '80%',
        margin: '0 auto',
    },
    productDetail: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '20px',
        padding: '40px',
    },
    imageContainer: {
        flex: '1 1 50%', // Flex-grow: 1, Flex-shrink: 1, Flex-basis: 50% of the space
        minWidth: '50%', // Ensure the image container takes at least 50% of the space
    },
    descriptionContainer: {
        flex: '1 1 50%', // Flex-grow: 1, Flex-shrink: 1, Flex-basis: 50% of the space
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        textAlign: 'left',
    },
    image: {
        width: '100%', // Image takes full width of the container
        height: 'auto',
    },
    addToCartButton: {
        width: '50%',
        padding: '10px 20px',
        marginTop: '10px',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
    },
    addedToCartMessage: {
        color: 'green',
        marginTop: '10px',
    },
};

export default ProductDetail;
