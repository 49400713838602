import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ProductList = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        // Fetch products from the Django API
        fetch('/api/products/')
            .then(response => response.json())
            .then(data => setProducts(data))
            .catch(error => console.error('Error fetching products:', error));
    }, []);

    return (
        <div className="product-list" style={styles.productList}>
            {products.map(product => (
                <Link to={`/product/${product.id}`} key={product.id} className="product-item" style={styles.productItem}>
                    <img src={product.images[0]?.image} alt={product.name} style={styles.image} />
                    <h2 style={styles.productName}>{product.name}</h2>
                    <p style={styles.description}>{product.description}</p>
                    <p style={styles.price}>${product.price}</p>
                </Link>
            ))}
        </div>
    );
};

const styles = {
    productList: {
        display: 'flex',
        flexWrap: 'wrap', // Allows items to wrap to the next line if necessary
        gap: '20px', // Space between each product tile
        justifyContent: 'center', // Center the product tiles
        padding: '20px',
    },
    productItem: {
        border: '1px solid #ccc', // 1px border around each product
        borderRadius: '8px', // Optional: add rounded corners
        padding: '15px',
        width: 'calc(25% - 20px)', // Each product takes 25% of the container width minus the gap
        boxSizing: 'border-box', // Ensures padding and border are included in the width
        textAlign: 'center',
        textDecoration: 'none',
        color: '#000',
    },
    image: {
        maxWidth: '100%',
        height: 'auto',
        marginBottom: '10px', // Space between the image and the product name
    },
    productName: {
        fontSize: '18px',
        marginBottom: '10px',
    },
    description: {
        fontSize: '14px',
        marginBottom: '10px',
        color: '#666',
    },
    price: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#000',
    },
};

export default ProductList;
